import { ConsentInfoComponent } from '../../project/check-consent/consent-info/consent-info.component';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { ConsentDocumentResponse } from '../../project-admin/consent-config/consent-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { UserAuthService } from './user-auth.service';
import { ModalOptions, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { Router } from '@angular/router';
import { Constants } from '../../../constants';
import { Location } from '@angular/common';

const PREVIEW_MODE: ModalOptions = {
  backdropClose: true,
  showClose: true,
  maxWidth: '90vw',
  escClose: true
};

const EDIT_MODE: ModalOptions = {
  showClose: false,
  maxWidth: '90vw',
  escClose: false
};

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private consentRevisionChange = new BehaviorSubject<number | null>(null);

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private userAuth: UserAuthService,
    private router: Router,
    private location: Location
  ) {
    this.subscribeToProjectChangeEvent();
  }

  get consentRevision() {
    return this.consentRevisionChange.getValue();
  }

  subscribeToProjectChangeEvent() {
    this.projectsService.projectConfigEvents.subscribe((projectConfig) => {
      this.consentRevisionChange.next(projectConfig?.config?.options?.consentRevision ?? null);
    });
  }

  awaitConsentDialogAccepted(redirectURL: string): Observable<boolean> {
    const ref = this.modalService.openComponent(ConsentInfoComponent, EDIT_MODE);
    ref.instance.readonly = false;
    return ref.instance.close.pipe(
      concatMap((consentAccepted) => {
        if (consentAccepted) {
          this.userAuth.performLogin(this.location.prepareExternalUrl(redirectURL));
          return NEVER;
        } else {
          // Navigate to error page for initial navigation's
          this.router.navigate([Constants.routing.error, 'missingConsent']);
        }
        this.modalService.close();
        return of(false);
      })
    );
  }

  openConsentDocumentPreview(revision = 'latest'): void {
    const ref = this.modalService.openComponent(ConsentInfoComponent, PREVIEW_MODE);
    ref.instance.readonly = true;
    ref.instance.revisionVersion = revision;
    ref.instance.close.subscribe(() => {
      this.modalService.close();
    });
  }

  deleteConsentDocument(revision: string): Observable<ConsentDocumentResponse> {
    return this.http
      .delete<ConsentDocumentResponse>(
        `/project-management-service/v1/${this.projectsService.projectName}/consents/${revision}`
      )
      .pipe(tap(() => this.changeConsentRevision('remove')));
  }

  uploadConsentDocument(file: File): Observable<ConsentDocumentResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<ConsentDocumentResponse>(
        `/project-management-service/v1/${this.projectsService.projectName}/consents`,
        formData
      )
      .pipe(tap(() => this.changeConsentRevision('add')));
  }

  listAllConsentDocuments(
    page = 0,
    pageSize = 20
  ): Observable<HttpResponse<ConsentDocumentResponse[]>> {
    return this.http
      .get<ConsentDocumentResponse[]>(
        `/project-management-service/v1/${this.projectsService.projectName}/consents`,
        {
          params: {
            page: String(page),
            pageSize: String(pageSize)
          },
          observe: 'response'
        }
      )
      .pipe(tap((response) => this.consentRevisionChange.next(response.body.length)));
  }

  getConsentDocumentByRevision(revision = 'latest'): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getConsentDocumentDownloadUrl(revision), {
      observe: 'response',
      responseType: 'blob'
    });
  }

  consentLatestDocument(): Observable<unknown> {
    return this.http.put(
      `/project-management-service/v1/${this.projectsService.projectName}/consents/user-consent`,
      {}
    );
  }

  getConsentDocumentDownloadUrl(revision = 'latest'): string {
    return `/project-management-service/v1/${this.projectsService.projectName}/consents/${revision}/download`;
  }

  checkLatestConsent(redirectURL: string): Observable<boolean> {
    return this.projectsService.getCurrentProject().pipe(
      switchMap((projectConfig) => {
        if (projectConfig?.latestConsentRequired) {
          return this.awaitConsentDialogAccepted(redirectURL);
        }
        return of(true);
      })
    );
  }

  private changeConsentRevision(operation: string) {
    if (operation === 'add') {
      this.consentRevisionChange.next(this.consentRevision + 1);
    }

    if (operation === 'remove') {
      this.consentRevisionChange.next(this.consentRevision - 1);
    }
  }
}
