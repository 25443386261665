import { ConsentService } from './../../../core/services/consent.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'consent-info',
  templateUrl: './consent-info.component.html'
})
export class ConsentInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly = false;

  consentLoader = new LoadingEntity<void>();

  resourceURL: SafeResourceUrl;

  consentDocumentFileName: string;

  revisionVersion = 'latest';

  agreeConsentCheckbox = false;

  close = new EventEmitter<boolean>();

  error;

  get projectName() {
    return (
      this.projectsService.projectConfig?.label[this.translateService.currentLang] ||
      this.projectsService.projectConfig?.label['en'] ||
      ''
    );
  }

  constructor(
    private consentService: ConsentService,
    private projectsService: ProjectsService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.consentLoader
      .run(
        this.consentService.getConsentDocumentByRevision(this.revisionVersion).pipe(
          map((response) => {
            this.setSanitizedResourceURL(response.body);
            this.setConsentDocumentFileInfos(response.headers);
          })
        )
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.consentLoader.complete();
    this.close.unsubscribe();
  }

  setSanitizedResourceURL(consentDocument: Blob) {
    const consentDocumentAsBlob = new Blob([consentDocument], { type: 'application/pdf' });
    const consentDocumentURL = window.URL.createObjectURL(consentDocumentAsBlob);
    this.resourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(consentDocumentURL);
  }

  setConsentDocumentFileInfos(headers: HttpHeaders) {
    const removeDoubleQuotesRegex = /(^")|("$)/g;
    this.consentDocumentFileName = headers
      .get('content-disposition')
      .replace('attachment; filename=', '')
      .replace(removeDoubleQuotesRegex, '');
    this.revisionVersion = headers.get('revision-number');
  }

  onClickHandler(acceptedConsent: boolean) {
    if (this.agreeConsentCheckbox && acceptedConsent) {
      this.onDocumentAccept();
    } else if (!acceptedConsent) {
      this.close.emit(false);
    }
  }

  onDocumentAccept() {
    if (this.agreeConsentCheckbox) {
      this.consentService.consentLatestDocument().subscribe({
        complete: () => this.close.emit(true),
        error: (error) => (this.error = error)
      });
    }
  }
}
