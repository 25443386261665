<rb-alert
  [type]="readonly ? 'info' : 'warning'"
  [alertTitle]="
    readonly
      ? ('projectConfig.consentManagement.consentPreview' | translate)
      : ('projectConfig.consentManagement.isPendingConsent' | translate)
  "
>
  <div class="d-flex align-items-baseline" data-cy="consentInfoHeadline">
    <h4>
      {{
        'projectConfig.consentManagement.consentInfoHeaderTitle'
          | translate: { versionCounter: revisionVersion }
      }}
    </h4>
  </div>

  <div *rbLoading="consentLoader.loading">
    <embed
      *ngIf="resourceURL"
      [src]="resourceURL"
      height="500px"
      type="application/pdf"
      width="100%"
    />
    <rb-notification-bar *ngIf="consentLoader.error" [type]="'error'">
      <rb-error-format [error]="consentLoader.error"></rb-error-format>
    </rb-notification-bar>
  </div>

  <a
    *ngIf="resourceURL"
    [download]="consentDocumentFileName"
    [href]="resourceURL"
    class="rb-btn rb-link mt-2 pl-1"
  >
    <em class="rb-ic rb-ic-download"></em>
    <span class="vlign-icons">
      {{ 'projectConfig.consentManagement.downloadConsentDocument' | translate }}
    </span>
  </a>

  <div *ngIf="!readonly">
    <rb-form-checkbox
      data-cy="consentInfoAgreeCheckbox"
      *ngIf="!consentLoader.error"
      [(ngModel)]="agreeConsentCheckbox"
    >
      {{ 'projectConfig.consentManagement.agreeConsentCheckboxLabel' | translate }}
    </rb-form-checkbox>

    <rb-notification-bar *ngIf="!agreeConsentCheckbox" type="warning">
      <div
        [innerHTML]="
          'projectConfig.consentManagement.agreeConsentWarning'
            | translate: { projectName: projectName }
        "
      ></div>
    </rb-notification-bar>

    <rb-callout *ngIf="error" type="error">
      <rb-error-format [error]="error"></rb-error-format>
    </rb-callout>

    <div class="mt-2 text-right">
      <button
        type="button"
        data-cy="consentInfoConsentFormButton"
        (click)="onClickHandler(true)"
        [disabled]="!agreeConsentCheckbox"
        class="rb-btn rb-primary"
      >
        {{ 'projectConfig.consentManagement.acceptConsentButtonLabel' | translate }}
      </button>
      <button
        type="button"
        data-cy="consentInfoRejectFormButton"
        (click)="onClickHandler(false)"
        class="rb-btn rb-secondary ml-2"
      >
        {{ 'projectConfig.consentManagement.rejectConsentButtonLabel' | translate }}
      </button>
    </div>
  </div>
</rb-alert>
